/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS321P
 * 화면 설명: 발행 상품설명서(체결용) 재발행 팝업
 */
<template>
  <ur-page-container class="msp" title="상품설명서 재발행" :show-title="true" :topButton="true"  type="subpage">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment con-area">
        <mo-segment-wrapper solid primary v-model="radioValue" class="">
          <mo-segment-button value="all">전체</mo-segment-button>
          <mo-segment-button value="each">계약자보관용</mo-segment-button>
          <mo-segment-button value="company">회사보관용</mo-segment-button>
        </mo-segment-wrapper>
      </ur-box-container>

      <!-- 계약자보관용 버튼 선택시 -->
      <ur-box-container v-show="eachPageShow" alignV="start" componentid="" direction="column">
        <div class="ns-certify-sed mb20 mt20">
          <div class="ns-certify-sed-code row-text">
            <!-- <mo-text-field class="full" :rules="validateRule1" v-model="pageStart01" underline placeholder="00" mask="number" maxlength="2" @focus="fn_OnfocusTextField('each')" />
            <span class="comp_spacing">-</span>
            <mo-text-field class="full" :rules="validateRule2" v-model="pageTo01" underline placeholder="00" mask="number" maxlength="2" @focus="fn_OnfocusTextField('each')" /> -->
            <mo-text-field class="full" v-model="pageStart01" underline placeholder="000" type="number" mask="###" maxlength="3" clearable @focus="fn_OnfocusTextField('each')" />
            <span class="comp_spacing">-</span>
            <mo-text-field class="full" v-model="pageTo01" underline placeholder="000" type="number" mask="###" maxlength="3" clearable @focus="fn_OnfocusTextField('each')" />
          </div>
        </div>
      </ur-box-container>
      <!-- 계약자보관용 버튼 선택시  -->

      <!-- 회사보관용 버튼 선택시 -->
      <ur-box-container v-show="companyPageShow" alignV="start" componentid="" direction="column">
        <div class="ns-certify-sed mb20 mt20">
          <div class="ns-certify-sed-code row-text">
            <!-- <mo-text-field class="full" :rules="validateRule3" v-model="pageStart02" underline placeholder="00" mask="number" maxlength="2" @focus="fn_OnfocusTextField('company')" />
            <span class="comp_spacing">-</span>
            <mo-text-field class="full" :rules="validateRule4" v-model="pageTo02" underline placeholder="00" mask="number" maxlength="2" @focus="fn_OnfocusTextField('company')" /> -->
            <mo-text-field class="full" v-model="pageStart02" underline placeholder="000" type="number" mask="###" maxlength="3" clearable @focus="fn_OnfocusTextField('company')" />
            <span class="comp_spacing">-</span>
            <mo-text-field class="full" v-model="pageTo02" underline placeholder="000" type="number" mask="###" maxlength="3" clearable @focus="fn_OnfocusTextField('company')" />
          </div>
        </div>
      </ur-box-container>
      <!-- 회사보관용 버튼 선택시  -->

      <!-- 공유일정 버튼 선택시 --> 
      <ur-box-container alignV="start" componentid="" direction="column">
        <ur-box-container alignV="start" componentid="" direction="column" class="msp-detail-lst mt30 pb10">  
          <ul class="lst">
            <li>
              <span class="tit">영수증번호</span>
              <span class="txt">{{rowPopupData.planId}}</span>
            </li>
            <li>
              <span class="tit">상품명</span>
              <span class="txt">{{rowPopupData.prdtNmLabel}}</span>
            </li>
            <li>
              <span class="tit">계약자</span>
              <span class="txt">{{rowPopupData.contrNm}}</span>
            </li>
            <li>
              <span class="tit">피보험자</span>
              <span class="txt">{{rowPopupData.mnisdNm}}</span>
            </li>
            <li>
              <span class="tit">최초발행일</span>
              <span class="txt">{{rowPopupData.inptYmdLabel}}</span>
            </li>
          </ul>
        </ur-box-container>
        <ur-box-container direction="column" align-v="start" class="ns-info-area mt10 mb50">
          <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
            <ur-box-container alignV="start" componentid="" direction="column" >
              <ul class="terms-list-area--gray">
                <li>
                  <p class="mb0">외국인 전용 상품설명서는 지점프로에게 인쇄 요청하시기 바랍니다.</p>
                  <p class="mt0">업무매뉴얼(이지스)에서 다운로드 가능합니다.</p>
                </li>
                <li>
                  <p class="mb0">기발행된 상품설명서 <em class="crTy-blue2 em_normal--2">영수증번호</em>를 반드시 확인하시기 바랍니다.</p>
                </li>
              </ul>
            </ur-box-container>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
    <!-- 공유일정 버튼 선택시 --> 

    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative noshadow">
        <div class="relative-div">
          <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel">취소</mo-button>
          <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm">확인</mo-button>
        </div>
      </ur-box-container>
    </template>

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS321P', 
  screenId: 'MSPPS321P', 
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pRowPopupData: {type: Object}
  },//props

  
  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      validateRule1: [
          v => this.isChkNo1 || this.validateMsg
        ],
      validateRule2: [
          v => this.isChkNo2 || this.validateMsg
        ],
      validateRule3: [
          v => this.isChkNo3 || this.validateMsg
        ],
      validateRule4: [
          v => this.isChkNo4 || this.validateMsg
        ],
      validateMsg: '1~12까지 입력해주십시오',
      isChkNo1: false,  //입력한 값 체크
      isChkNo2: false,  //입력한 값 체크
      isChkNo3: false,  //입력한 값 체크
      isChkNo4: false,  //입력한 값 체크
      eachPageShow : false,
      companyPageShow : false,
      rowPopupData : {},
      radioValue: 'all',
      pageStart01: '',
      pageTo01: '',
      pageStart02: '',
      pageTo02: ''
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    this.rowPopupData = (this.pRowPopupData)? this.pRowPopupData : {}
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {},//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
     radioValue () {
      let authOrgCd = PSServiceManager.getUserInfo('authOrgCd')
      let srvcMngScCd = PSServiceManager.getUserInfo('srvcMngScCd')
      if (this.radioValue !== 'all' && srvcMngScCd === 'GA' && (authOrgCd === 'RG0090' || authOrgCd === 'RG0223' || authOrgCd === 'RG0693') ) {
        this.radioValue = 'all'
        this.getStore('confirm').dispatch('ADD', 'GA비전속 설계사인 경우 전체페이지 인쇄만 가능합니다.')
      } // end if

      //this.$bizUtil.clearValidator(['regist'])
     
     if (this.radioValue === 'each') { // 계약자보관용
        this.pageStart02 = ''
        this.pageTo02 = ''
        this.eachPageShow = true
        this.companyPageShow = false
      } else if (this.radioValue === 'company') { // 회사보관용
        this.pageStart01 = ''
        this.pageTo01 = ''
        this.eachPageShow = false
        this.companyPageShow = true
      } else { // 전체
        this.pageStart01 = ''
        this.pageTo01 = ''
        this.pageStart02 = ''
        this.pageTo02 = ''
        this.eachPageShow = false
        this.companyPageShow = false
      } // end else if
    },
    pageStart01() {
      if (this.pageStart01 <= 12) {
        this.isChkNo1 = true
      } else {
        this.isChkNo1 = false
      }
      return this.isChkNo1
    },
    pageTo01() {
      if (this.pageTo01 <= 12) {
        this.isChkNo2 = true
      } else {
        this.isChkNo2 = false
      }
      return this.isChkNo2
    },
    pageStart02() {
      if (this.pageStart02 <= 12) {
        this.isChkNo3 = true
      } else {
        this.isChkNo3 = false
      }
      return this.isChkNo3
    },
    pageTo02() {
      if (this.pageTo02 <= 12) {
        this.isChkNo4 = true
      } else {
        this.isChkNo4 = false
      }
      return this.isChkNo4
    },
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명  : fn_Cancel
    * 설명        : 취소버튼 클릭 핸들러
    ******************************************************************************/
    fn_Cancel () {
      this.$emit('onPopupCancel',null)      
    },

    /******************************************************************************
    * Function명  : fn_Confirm
    * 설명        : 학인버튼 클릭 핸들러
    ******************************************************************************/
    fn_Confirm () {
       this.fn_ServiceData('T') // 상설(제안용) 재발행 처리
    },

    /******************************************************************************
    * Function명  : fn_SampleMethods1
    * 설명        : 샘플 서비스 호출 함수
    ******************************************************************************/
    fn_OnfocusTextField (pRadioValue) {
      this.radioValue = pRadioValue      
    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/

   /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      switch (serviceName) {
        case 'T': // 상설(체결용) 재발행 처리
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS28T2'))
          pParams.data = {}
          pParams.data.planId = this.rowPopupData['planId'] // 영수증 번호
          pParams.data.pblYn = 'Y' // 발행여부
          pParams.data.planId = this.rowPopupData['planId'] // 설계ID
          pParams.data.clctCnsltNo = PSServiceManager.getUserInfo('userId') // 모집컨설턴트번호
          let strPageNo = 0
          let endPageNo = 0
          if (this.radioValue === 'all') { // 전체
            pParams.data.partPblScNm = '01' // 부분발행여부
            this.pageStart01 = ''
            this.pageTo01 = ''
            this.pageStart02 = ''
            this.pageTo02 = ''
          } else if (this.radioValue === 'each') { // 계약자 보관용
            pParams.data.partPblScNm = '02' // 부분발행여부
            strPageNo = this.fn_StringToint(this.pageStart01)
            endPageNo = this.fn_StringToint(this.pageTo01)
          } else { // 회사 보관용
            pParams.data.partPblScNm = '03' // 부분발행여부
            strPageNo = this.fn_StringToint(this.pageStart02)
            endPageNo = this.fn_StringToint(this.pageTo02)
          } // end else if
          if (this.radioValue !== 'all') {
            // 시작페이지
            if (strPageNo > 0 && strPageNo <= endPageNo) {
              pParams.data.strPageNo = strPageNo
            } else {
              this.getStore('confirm').dispatch('ADD', '페이지 입력이 잘못되었습니다.')
              return
            } // end else if
            // 종료페이지
            if (endPageNo > 0 && strPageNo <= endPageNo) {
              pParams.data.endPageNo = endPageNo
            } else {
              this.getStore('confirm').dispatch('ADD', '페이지 입력이 잘못되었습니다.')
              return
            } // end else if
          } // end if
          break
        default:
          break
      } // end switch
      if (this.radioValue == 'each') {
        // if (!this.isChkNo1 || !this.isChkNo2) return
      }
      if (this.radioValue == 'company') {
        // if (!this.isChkNo3 || !this.isChkNo4) return
      }
      
      pParams.srnId = this.$options.screenId // 대표 화면 명

      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
    },

    /************************************************************************************************
     * Function명  : fn_ServiceDataResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      this.$emit('onPopupClose',null)

      let lv_data = event.data
      switch (serviceName) {
        case 'T': // 상설(체결용) 재발행 처리
          if (lv_data) {
            this.getStore('confirm').dispatch('ADD', lv_data.rsltCntnt)
          } // end if
          break
        default:
          break
      }

    },
    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {      
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

    /************************************************************************************************
     * Function명 : fn_StringToint
     * 설명       : 문자 to Int return
     ************************************************************************************************/
    fn_StringToint: function (value) {
      return !isNaN(parseInt(value))? parseInt(value) : '0'
    },


    _fn_End(){return}//코딩 종료 함수 메서드 함수 , 없음 주의

 },// methods

}//export default


</script>